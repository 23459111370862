<template>
    <div class="container">
        <crud ref="crud" :options="options"></crud>
    </div>
</template>

<script>
export default {
    name: "Template",
    data: function () {
        return {
            codes: [],
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'resource.smsTemplate.add',
                addPerm: 'resource_smstemplate_add',
                editUrl: 'resource.smsTemplate.edit',
                editPerm: 'resource_smstemplate_edit',
                delUrl: 'resource.smsTemplate.delete',
                delPerm: 'resource_smstemplate_delete',
                listUrl: 'resource.smsTemplate.page',
                formLabelWidth: '100px',
                columns: [
                    {
                        prop: "code",
                        label: "模板标识",
                        required: true,
                        type: 'select',
                        dicUrl: 'resource.smsTemplate.codes',
                        dicData: [],
                        dicProps: {label: 'label', value: 'value'},
                        formatter: row => {
                            return this.codes[row.code]
                        }
                    },
                    {prop: "template", label: "模板ID", required: true},
                    {prop: "remark", label: "备注",}
                ],
            }
        }
    },
    created: function () {
        this.$helper._get(this, 'resource.smstemplate.codes', {kv: true}, resp => {
            this.codes = resp.data
        })
    },
    methods: {}
}
</script>

<style scoped>

</style>
